
export default {
  API_BASE_URL: 'http://localhost:8001',
  // Polygon
  CHAIN_ID: 137, // 80001: testnet, 137: mainnet
  MAINNET_RPC: 'https://rpc-mainnet.maticvigil.com/',
  CSPN_ADDRESS: '0x9A0b381394fbE689B344d1ebd2d4DccFF31adf87',
  STAKING_ADDRESS: '0x8D2193aDcd61E604b56455CF9F9F14f1a094b683',
  EXPLORER_LINK : 'https://polygonscan.com/',

  //Parse
  PARSE_APP_ID: 'CSPNPROPARSE',
  PARSE_URL: 'https://parse.cspn.pro',
  PARSE_MASTER_KEY: 'CSPNFHJETZDSWYNMSLKSDHTZUDI',
  //PARSE_JS_KEY: 'mhVydw07ByzKkxV2T6dLbsOlawACekwQ4bj0dMcS',
  //PARSE_DEFAULT_PASSWORD: 'cspn_pwd',
}
