import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/index.vue'

// import store from '../store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('ROUTER MIDDLEWARE', store.state.account);
  // if (to.name !== 'Home' && !isAuthenticated) next({ name: 'Login' })
  // else next()
  next();
})

export default router
