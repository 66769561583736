import Web3 from "web3";

class Utils {
  formatAddress(string, size) {
    if (!string) {
      return string;
    }
    size = size || 4;
    if (string.length < size * 2 + 3) {
      return string;
    }
    return string.substring(0, size) + '...' + string.substr(-size);
  }
  sleep(seconds) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, seconds * 1000);
    });
  }
  formatBigInt(int) {
    // eslint-disable-next-line no-undef
    return '0x' + BigInt(int).toString(16);
  }
  loadScript(script) {
    const jQuery = window.jQuery;
    return new Promise((resolve) => {
      jQuery.getScript(script).done(() => {
        resolve();
      });
    })
  }

  formatBalance(balance, decimals) {
    if (!decimals) {
      decimals = 18;
    }
    // eslint-disable-next-line no-undef
    // return parseInt(BigInt(balance * 1000) / BigInt(10 ** decimals)) / 1000;
    const temp = Number(Web3.utils.fromWei(balance));
    if(temp >= 100) {
      return parseFloat(Number(Web3.utils.fromWei(balance).toString().match(/^\d+(?:\.\d{0,3})?/)));
    } else if(temp >=10 && temp < 100) {
      return parseFloat(Number(Web3.utils.fromWei(balance).toString().match(/^\d+(?:\.\d{0,4})?/)));
    }else if(temp >=1 && temp < 10) {
      return parseFloat(Number(Web3.utils.fromWei(balance).toString().match(/^\d+(?:\.\d{0,5})?/)));
    }else if(temp >=0.1 && temp < 1) {
      return parseFloat(Number(Web3.utils.fromWei(balance).toString().match(/^\d+(?:\.\d{0,6})?/)));
    }else if(temp >=0.01 && temp < 0.1) {
      return parseFloat(Number(Web3.utils.fromWei(balance).toString().match(/^\d+(?:\.\d{0,7})?/)));
    }else if(temp >=0.001 && temp < 0.01) {
      return parseFloat(Number(Web3.utils.fromWei(balance).toString().match(/^\d+(?:\.\d{0,8})?/)));
    } else {
      return parseFloat(Number(Web3.utils.fromWei(balance).toString().match(/^\d+(?:\.\d{0,9})?/)));
    }
  }
  copyToClipboard(text) {
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(text);
  }
}
export default new Utils();