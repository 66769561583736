const parse = require("parse");
import config from "../config";
import Web3 from "./Web3";

export class ParseHelper {
  constructor() {
    this._initilized = false;
  }

  get client() {
    return parse;
  }

  instance() {
    return parse;
  }

  getClass(className) {
    return parse.Object.extend(className);
  }

  getUserClass() {
    return parse.User;
  }

  getQuery(className) {
    return new parse.Query(className);
  }

  destroy() {
    return Promise.all([]);
  }

  init(isRefresh) {
    if (!this._initilized || isRefresh) {
      parse.initialize(config.PARSE_APP_ID, config.PARSE_JS_KEY);
      parse.serverURL = config.PARSE_URL;
    }

    this.destroy().then(() => {
      this._initilized = true;
    });
  }

  getChatQuery() {
    const query = new parse.Query("History");
    // TODO: Customize the query depending on the user
    return query;
  }

  callCloud(func, params) {
    return parse.Cloud.run(func, params);
  }

  async getHistory(address, skip = 0, limit = 100, searchBy) {
    const History = this.getClass("History");
    const query = this.getQuery(History);
    // query.equalTo("address", address);
    query.matches("address", address, "i");
    if (searchBy != "All") query.equalTo("action", searchBy);
    query.descending("createdAt");
    query.skip(skip);
    query.limit(parseInt(limit));
    return query.find();
  }

  async getReinvestHistory(address, skip = 0, limit = 100) {
    const History = this.getClass("History");
    const query = this.getQuery(History);
    query.matches("address", address, "i");
    query.matches("action", "Reinvest", "i");
    query.skip(skip);
    query.limit(parseInt(limit));
    return query.find();
  }

  async getStatus() {
    const History = this.getClass("Status");
    const query = this.getQuery(History);

    const result = await query.find();
    if (!result.length) {
      await Web3.saveStatus();
      await this.getStatus();
    } else {
      return query.first();
    }
  }

  async getReinvestAmount(address) {
    let index = 0;
    let total_reward = 0;
    let stopped = false;
    while (!stopped) {
      const result = await this.getReinvestHistory(address, index * 100, 100);
      if (result.length == 0) {
        stopped = true;
      }
      for (let i = 0; i < result.length; i++) {
        total_reward += parseFloat(result[i].get("amount"));
      }
      index++;
    }
    return total_reward;
  }

  async saveStatus(total_stake, total_reward, total_available) {
    const Status = this.getClass("Status");
    const query = this.getQuery(Status);

    const result = await query.find();
    if (result.length > 0) {
      const status = await query.first();
      status.set("total_stake", total_stake);
      status.set("total_reward", total_reward);
      status.set("total_available", total_available);
      await status.save();
    } else {
      const status = new Status();
      status.set("total_stake", total_stake);
      status.set("total_reward", total_reward);
      status.set("total_available", total_available);
      await status.save();
    }
  }

  async saveHistory(address, action, amount, txid) {
    const History = this.getClass("History");
    const history = new History();
    history.set("address", address);
    history.set("action", action);
    history.set("amount", amount.toString());
    history.set("txid", txid);
    await history.save();
  }
}

const instance = new ParseHelper();
instance.init();
export default instance;