import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const Store = new Vuex.Store({
  state: {
    address: null,
    balance: 0,
    staking: 0,
    reward: 0,
    total_reward: 0,
    isApproved: false,
  },
  getters: {
    address: (state) => state.address,
    balance: (state) => state.balance,
    staking: (state) => state.staking,
    reward: (state) => state.reward,
    total_reward: (state) => state.total_reward,
    isApproved: (state) => state.isApproved,
  },
  actions: {
  },
  mutations: {
    SET (state, [key, value]) {
      state[key] = value;
    }
  }
})

export default Store;