<template>
  <div class="main-container" id="app">
    <div id="header">
      <img id="logo" src="img/logo.png" />
      <media :query="{ minWidth: 516 }">
        <div v-if="!address" id="connectWallet" @click="connect">
          Connect Wallet
        </div>
        <div v-else id="connectWallet" @click="disconnect">
          Disconnect Wallet
          <div class="address">{{ formatAddress(address) }}</div>
        </div>
      </media>
      <media :query="{ maxWidth: 515 }">
        <div v-if="!address" id="connectWallet" @click="menuOpen = !menuOpen">
          Menu
        </div>
        <div v-else id="connectWallet" @click="menuOpen = !menuOpen">
          Menu
          <div class="address">{{ formatAddress(address) }}</div>
        </div>
      </media>
      <div v-if="menuOpen" class="mobile-menu">
        <div
          @click="
            active = 0;
            menuOpen = !menuOpen;
          "
        >
          Overview
        </div>
        <div
          @click="
            active = 1;
            menuOpen = !menuOpen;
          "
          v-if="address"
        >
          Stake cspn
        </div>
        <div
          @click="
            active = 2;
            menuOpen = !menuOpen;
          "
          v-if="address"
        >
          Harvest cspn
        </div>
        <div
          @click="
            active = 3;
            menuOpen = !menuOpen;
          "
          v-if="address"
        >
          Withdraw cspn
        </div>
        <div
          @click="
            active = 4;
            menuOpen = !menuOpen;
          "
          v-if="address"
        >
          Statistics
        </div>
        <div
          @click="
            active = 5;
            menuOpen = !menuOpen;
          "
        >
          How it works
        </div>
        <div
          @click="
            active = 6;
            menuOpen = !menuOpen;
          "
        >
          Staking calculator
        </div>
        <div v-if="!address" @click="connect">Connect Wallet</div>
        <div v-else @click="disconnect">Disconnect Wallet</div>
      </div>
      <div v-if="menuOpen" class="menuBack" @click="menuOpen = false"></div>
    </div>
    <div id="content" ref="content">
      <div id="tabs">
        <div class="tabs">
          <div
            class="tab content-trigger"
            v-bind:class="active == 0 ? 'active' : null"
            data-trigger="overview"
            @click="active = 0"
          >
            Overview
          </div>
          <div v-if="address">
            <div
              id="stake"
              class="tab content-trigger connected"
              data-trigger="stake"
              v-bind:class="active == 1 ? 'active' : 'null'"
              @click="active = 1"
            >
              Stake CSPN
            </div>
            <div
              id="harvest"
              class="tab content-trigger connected"
              data-trigger="harvest"
              v-bind:class="active == 2 ? 'active' : 'null'"
              @click="active = 2"
            >
              Harvest CSPN
            </div>
            <div
              id="withdraw"
              class="tab content-trigger connected"
              data-trigger="withdraw"
              v-bind:class="active == 3 ? 'active' : 'null'"
              @click="active = 3"
            >
              Withdraw CSPN
            </div>
            <div
              class="tab content-trigger connected"
              data-trigger="stats"
              v-bind:class="active == 4 ? 'active' : 'null'"
              @click="active = 4"
            >
              Statistics
            </div>
          </div>
          <div
            class="tab content-trigger"
            data-trigger="info"
            v-bind:class="active == 5 ? 'active' : 'null'"
            @click="active = 5"
          >
            How it works
          </div>
          <div
            id="calc"
            class="tab content-trigger"
            data-trigger="calc"
            v-bind:class="active == 6 ? 'active' : 'null'"
            @click="active = 6"
          >
            Staking Calculator
          </div>
        </div>
        <div class="tabs-mobile">
          <div
            class="tab content-trigger"
            v-bind:class="active == 0 ? 'active' : null"
            data-trigger="overview"
            v-if="active == 0"
          >
            Overview
          </div>
          <div
            id="stake"
            class="tab content-trigger connected"
            data-trigger="stake"
            v-bind:class="active == 1 ? 'active' : 'null'"
            v-if="active == 1"
          >
            Stake CSPN
          </div>
          <div
            id="harvest"
            class="tab content-trigger connected"
            data-trigger="harvest"
            v-bind:class="active == 2 ? 'active' : 'null'"
            v-if="active == 2"
          >
            Harvest CSPN
          </div>
          <div
            id="withdraw"
            class="tab content-trigger connected"
            data-trigger="withdraw"
            v-bind:class="active == 3 ? 'active' : 'null'"
            v-if="active == 3"
          >
            Withdraw CSPN
          </div>
          <div
            id="stats"
            class="tab content-trigger connected"
            data-trigger="stats"
            v-bind:class="active == 4 ? 'active' : 'null'"
            v-if="active == 4"
          >
            Statistics
          </div>
          <div
            class="tab content-trigger"
            data-trigger="info"
            v-bind:class="active == 5 ? 'active' : 'null'"
            v-if="active == 5"
          >
            How it works
          </div>
          <div
            id="calc"
            class="tab content-trigger"
            data-trigger="calc"
            v-bind:class="active == 6 ? 'active' : 'null'"
            v-if="active == 6"
          >
            Staking Calculator
          </div>
        </div>
        <div class="content">
          <div
            id="overview_content"
            class="tab_content"
            v-bind:class="active == 0 ? 'active' : 'null'"
          >
            <div v-if="address" class="connected">
              <p>
                Available Balance:
                <strong
                  ><span class="balance value">{{
                    numberFormat(formatBalance(balance))
                  }}</span>
                  CSPN</strong
                >
                <a
                  class="buy-more"
                  href="https://www.cspn.io/#exchanges"
                  target="_blank"
                  ><i class="fas fa-wallet"></i> Buy More</a
                >
              </p>
              <p>
                Currently Staking:
                <strong
                  ><span class="staking value">{{
                    numberFormat(formatBalance(staking))
                  }}</span>
                  CSPN</strong
                >
                <button
                  v-if="!isApproved"
                  id="button_addmore"
                  class="trigger-button"
                  @click="approve"
                  v-bind:class="[isLoadingApprove ? 'pRight' : '']"
                  :disabled="isLoadingApprove"
                >
                  Approve
                  <div v-if="isLoadingApprove" class="spinner"></div>
                </button>
                <button
                  v-else-if="formatBalance(staking) != 0"
                  id="button_addmore"
                  class="trigger-button"
                  @click="active = 1"
                >
                  Stake More
                </button>
                <button
                  v-else
                  id="button_addmore"
                  class="trigger-button"
                  @click="active = 1"
                >
                  Stake Now
                </button>
                <button
                  v-if="formatBalance(staking) != 0"
                  id="button_withdraw"
                  class="trigger-button"
                  @click="active = 3"
                >
                  Withdraw
                </button>
              </p>
              <p>
                Rewards Available:
                <strong
                  ><span class="rewards_av value">{{
                    numberFormat(formatBalance(reward))
                  }}</span>
                  CSPN</strong
                >
                <button
                  v-if="formatBalance(reward) != '0'"
                  id="button_harvest"
                  class="trigger-button"
                  @click="active = 2"
                >
                  Harvest
                </button>
              </p>
              <p style="display:none;">
                Total Rewards Received:
                <strong
                  ><span class="rewards_total value">{{
                    numberFormat(formatBalance(total_reward))
                  }}</span>
                  CSPN</strong
                >
              </p>
              <hr>
              <h3>Your Staking Performance</h3>
              <p>
                APY: <strong><span id="pfm_apy">0</span>%</strong><br>
                Rewards per Day: <strong><span id="pfm_day">0</span> CSPN</strong><br>
                Rewards per Week: <strong><span id="pfm_week">0</span> CSPN</strong><br>
                Rewards per Month: <strong><span id="pfm_month">0</span> CSPN</strong><br>
                Rewards per Year: <strong><span id="pfm_year">0</span> CSPN</strong>
              </p>
            </div>
            <div v-else class="disconnected">
              <h2>Welcome to the Official CSPN Staking dApp.</h2>
              <p class="stats stats1">
                Total CSPN staking:<br />
                <strong
                  ><span class="rewards_total value">{{
                    status != null
                      ? numberFormat(formatBalance(status.get("total_stake")))
                      : "_"
                  }}</span>
                  CSPN</strong
                >
              </p>
              <p class="stats stats1">
                Total Rewards paid:<br />
                <strong
                  ><span class="rewards_total value">{{
                    status != null
                      ? numberFormat(formatBalance(status.get("total_reward")))
                      : "_"
                  }}</span>
                  CSPN</strong
                >
              </p>
              <p class="stats">
                Total Rewards available:<br />
                <strong
                  ><span class="rewards_total value">{{
                    status != null
                      ? numberFormat(formatBalance(status.get("total_available")))
                      : "_"
                  }}</span>
                  CSPN</strong
                >
              </p>
              <p class="su-msg">
                Please
                <span class="connect-trigger trigger-button" @click="connect"
                  >Connect Your Wallet</span
                >
                in order to stake your CSPN and receive staking information.
              </p>
            </div>
          </div>
          <div
            id="stake_content"
            class="tab_content"
            v-bind:class="active == 1 ? 'active' : 'null'"
          >
            <div class="connected">
              <p>
                Currently Staking:
                <strong
                  ><span class="staking value">{{
                    numberFormat(formatBalance(staking))
                  }}</span>
                  CSPN</strong
                >
              </p>
              <p>
                Available Balance:
                <strong
                  ><span
                    class="balance value autofill"
                    data-trigger="add_stake"
                    data-amount="1000"
                    @click="amountAdd = formatBalance(balance)"
                    >{{ numberFormat(formatBalance(balance)) }}</span
                  >
                  CSPN</strong
                >
                <a
                  class="buy-more"
                  href="https://www.cspn.io/#exchanges"
                  target="_blank"
                  ><i class="fas fa-wallet"></i> Buy More</a
                >
              </p>
              <p>
                Amount to add:
                <input
                  type="text"
                  name="add_stake"
                  id="add_stake"
                  size="4"
                  autocomplete="off"
                  v-model="amountAdd"
                />
                CSPN
                <span
                  class="balance value autofill"
                  data-trigger="add_stake"
                  data-amount="1000"
                  @click="amountAdd = formatBalance(balance)"
                  >MAX</span
                >
              </p>
              <button
                v-if="isApproved"
                id="add_stake_confirm"
                class="action_confirm"
                v-bind:class="[isLoadingStake ? 'pRight' : '']"
                :disabled="isLoadingStake"
                @click="stake"
              >
                Add To Stake
                <div v-if="isLoadingStake" class="spinner"></div>
              </button>
              <button
                v-else
                id="add_stake_confirm"
                class="action_confirm"
                @click="approve"
                v-bind:class="[isLoadingApprove ? 'pRight' : '']"
                :disabled="isLoadingApprove"
              >
                Approve
                <div v-if="isLoadingApprove" class="spinner"></div>
              </button>
            </div>
          </div>
          <div
            id="harvest_content"
            class="tab_content"
            v-bind:class="active == 2 ? 'active' : 'null'"
          >
            <div class="connected">
              <p>
                Rewards Available:
                <strong
                  ><span
                    class="rewards_av value autofill"
                    data-trigger="harvest_rewards"
                    data-amount="0.685"
                    @click="amountHarvest = formatBalance(reward)"
                    >{{ numberFormat(formatBalance(reward)) }}</span
                  >
                  CSPN</strong
                >
              </p>
              <p>
                Amount to harvest / reinvest:
                <input
                  type="text"
                  name="add_stake"
                  id="harvest_rewards"
                  size="4"
                  autocomplete="off"
                  v-model="amountHarvest"
                />
                CSPN
                <span
                  class="balance value autofill"
                  data-trigger="harvest_rewards"
                  data-amount="0.685"
                  @click="amountHarvest = formatBalance(reward)"
                  >MAX</span
                >
              </p>
              <button
                id="harvest_confirm"
                class="action_confirm"
                v-bind:class="[isLoadingHarvest ? 'pRight' : '']"
                :disabled="isLoadingHarvest"
                @click="harvest"
              >
                Harvest
                <div v-if="isLoadingHarvest" class="spinner"></div>
              </button>
              <button
                id="reinvest_confirm"
                class="action_confirm"
                v-bind:class="[isLoadingReinvest ? 'pRight' : '']"
                :disabled="isLoadingReinvest"
                @click="reinvest"
              >
                Reinvest
                <div v-if="isLoadingReinvest" class="spinner"></div>
              </button>
            </div>
          </div>
          <div
            id="withdraw_content"
            class="tab_content"
            v-bind:class="active == 3 ? 'active' : 'null'"
          >
            <div class="connected">
              <p>
                Currently Staking:
                <strong
                  ><span
                    class="staking value autofill"
                    data-trigger="withdraw_stake"
                    data-amount="500"
                    @click="amountWithdraw = formatBalance(staking)"
                    >{{ numberFormat(formatBalance(staking)) }}</span
                  >
                  CSPN</strong
                >
              </p>
              <p>
                Amount to withdraw:
                <input
                  type="text"
                  name="add_stake"
                  id="withdraw_stake"
                  size="4"
                  autocomplete="off"
                  v-model="amountWithdraw"
                />
                CSPN
                <span
                  class="balance value autofill"
                  data-trigger="withdraw_stake"
                  data-amount="500"
                  @click="amountWithdraw = formatBalance(staking)"
                  >MAX</span
                >
              </p>
              <button
                id="withdraw_confirm"
                class="action_confirm"
                v-bind:class="[isLoadingWithdraw ? 'pRight' : '']"
                :disabled="isLoadingWithdraw"
                @click="withdraw"
              >
                Withdraw
                <div v-if="isLoadingWithdraw" class="spinner"></div>
              </button>
            </div>
          </div>
          <div
            id="stats_content"
            class="tab_content"
            v-bind:class="active == 4 ? 'active' : 'null'"
          >
            <div v-if="address" class="connected">
              <div style="float:left; width:100%;"> 
                <p class="stats stats1">
                  Total CSPN staking:<br />
                  <strong
                    ><span class="rewards_total value">{{
                      status != null
                        ? numberFormat(formatBalance(status.get("total_stake")))
                        : "_"
                    }}</span>
                    CSPN</strong
                  >
                </p>
                <p class="stats stats1">
                  Total Rewards harvested:<br />
                  <strong
                    ><span class="rewards_total value">{{
                      status != null
                        ? numberFormat(formatBalance(status.get("total_reward")))
                        : "_"
                    }}</span>
                    CSPN</strong
                  >
                </p>
                <p class="stats">
                  Total Rewards available:<br />
                  <strong
                    ><span class="rewards_total value">{{
                      status != null
                        ? numberFormat(formatBalance(status.get("total_available")))
                        : "_"
                    }}</span>
                    CSPN</strong
                  >
                </p>
              </div>
            </div> 
          </div>
          <div
            id="info_content"
            class="tab_content"
            style="width: 100%"
            v-bind:class="active == 5 ? 'active' : 'null'"
          >
            <h3>
              Stake your CSPN and be rewarded with up to 20% Annual Percentage
              Yield (APY).
            </h3>
            <p>
              By staking your CSPN, you help reduce the circulating supply,
              which boosts the CSPN market value and rewards you in return for
              your support.
              <br /><br />
              <strong><u>No Minimum Lock-Time!</u></strong
              ><br />
              Enjoy complete flexibility and control over your CPN Tokens -
              Stake, Withdraw, Harvest & Reinvest how you want it, whenever you
              want it.
              <br /><br />
              <strong
                >Check the table below to see what your reward will be depending
                on the amount of CSPN you stake:</strong
              >
              <br /><br />
            </p>
            <table>
              <thead>
                <th>CSPN Amount (From)</th>
                <th>CSPN Amount (To)</th>
                <th>APY</th>
              </thead>
              <tbody>
                <tr>
                  <td>0.1</td>
                  <td>1,336.9</td>
                  <td>10%</td>
                </tr>
                <tr>
                  <td>1,337</td>
                  <td>9,999</td>
                  <td>13.37%</td>
                </tr>
                <tr>
                  <td>10,000</td>
                  <td>24,999</td>
                  <td>15%</td>
                </tr>
                <tr>
                  <td>25,000</td>
                  <td>49,999</td>
                  <td>16.5%</td>
                </tr>
                <tr>
                  <td>50,000</td>
                  <td>99,999</td>
                  <td>18%</td>
                </tr>
                <tr>
                  <td>100,000</td>
                  <td>&infin;</td>
                  <td>20%</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <strong>
              To find out exactly what rewards you will receive, simply use our
              <button
                id="button_calc"
                class="trigger-button"
                @click="active = 5"
              >
                Staking Calculator
              </button>
              !
            </strong>
          </div>
          <div
            id="calc_content"
            class="tab_content"
            v-bind:class="active == 6 ? 'active' : 'null'"
          >
            <h3>Calculate your Rewards</h3>
            <p>
              <input id="cspn_amount" value="0" v-model="cspn_amount" />
              CSPN<br />
              <button id="calculate" class="action_confirm" @click="calculate">
                Calculate
              </button>
            </p>
            <p id="calc_result">
              Your APY:
              <strong
                ><span id="result_apy">{{ cspn_apy }}</span>%</strong
              ><br />
              Rewards per Day:
              <strong
                ><span id="result_day">{{ cspn_day }}</span> CSPN</strong
              ><br />
              Rewards per Week:
              <strong
                ><span id="result_year">{{ cspn_week }}</span> CSPN</strong
              ><br />
              Rewards per Month:
              <strong
                ><span id="result_year">{{ cspn_month }}</span> CSPN</strong
              ><br />
              Rewards per Year:
              <strong
                ><span id="result_year">{{ cspn_year }}</span> CSPN</strong
              ><br />
            </p>
          </div>
        </div>
      </div>
      <div v-if="address" id="table_content" class="connected">
        <h3>Transaction History</h3>
        <div class="table_navbar">
          <div>
            <span>Show </span>
            <select v-model="pageNum">
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </select>
            <span> entries</span>
          </div>
          <div>
            <span>Search by: </span>
            <select v-model="searchBy">
              <option>All</option>
              <option>Stake</option>
              <option>Withdraw</option>
              <option>Harvest</option>
              <option>Reinvest</option>
            </select>
          </div>
        </div>
        <div
          style="display: flex"
          v-bind:class="isLoading ? 'min_height' : 'null'"
        >
          <div class="loader" v-if="isLoading">Loading...</div>
          <table v-else style="margin-top: 40px">
            <thead>
              <th>NO</th>
              <th>TIME</th>
              <th>ACTION</th>
              <th>TX ID</th>
              <th>AMOUNT</th>
            </thead>
            <tbody v-if="results.length > 0">
              <tr v-for="(item, index) in results" v-bind:key="index">
                <td>{{ index + 1 + pageNum * pageIndex }}</td>
                <td>{{ formatDate(item.get("createdAt")) }}</td>
                <td>{{ item.get("action") }}</td>
                <td>
                  <a
                    v-bind:href="
                      'https://polygonscan.com/tx/' + item.get('txid')
                    "
                    target="_blink"
                  >
                    <media :query="{ minWidth: 850 }">
                      {{ item.get("txid") }}</media
                    >
                    <media :query="{ maxWidth: 850 }">
                      {{ formatAddress(item.get("txid")) }}</media
                    >
                  </a>
                </td>
                <td>
                  {{
                    numberFormat(format_Balance(item.get("amount")))
                  }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">No result</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table_footer">
          <div style="display: flex">
            <div class="table-page-btn" @click="previousPage">Previous</div>
            <div class="table-page-num">{{ pageIndex + 1 }}</div>
            <div class="table-page-btn" @click="nextPage">Next</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <!-- <alert-modal
      :title="alertModalTitle"
      :icon="alertModalIcon"
      :active="alertModalActive"
      :content="alertModalContent"
      :btnOk="alertModalBtnOk"
      :callback="alertModalCallback"
      @ok="alertModalActive = false"
    /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import Navbar from "@/views/Navbar";
import Footer from "@/views/Footer";
// import addressList from '@/components/addressList.vue';
// import InformationList from '@/components/InformationList.vue';
import { mapGetters } from "vuex";
import Web3 from "@/helpers/Web3";
import Observer from "@/helpers/Observer";
import Utils from "@/helpers/Utils";
import Parse from "@/helpers/Parse";
// import TransactionConfig from './components/TransactionConfig.vue';
// import AlertModal from "@/components/AlertModal.vue";
import { E_REJECT_SIGN, E_SIGNED } from "@/constants/events";
import Media from "vue-media";
import dateFormat from "dateformat";

export default {
  name: "Home",
  components: {
    // Navbar,
    Footer,
    Media,
    // AlertModal,
  },
  beforeDestroy() {
    Observer.$off(E_REJECT_SIGN);
  },
  methods: {
    async disconnect() {
      this.menuOpen = false;
      this.active = 0;
      this.pageNum = 10;
      this.searchBy = "All";
      this.pageIndex = 0;
      await Web3.disconnect();
    },
    numberFormat(x) {
      var value = parseFloat(x).toLocaleString("en-US", {
        minimumFractionDigits: 0, maximumFractionDigits: 9,
      });
      return value;
    },
    toFixed(x_) {
      let x = x_;
      let e;
      if (Math.abs(x) < 1.0) {
        e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10,e-1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
      } else {
        e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
          e -= 20;
          x /= Math.pow(10,e);
          x += (new Array(e+1)).join('0');
        }
      }
      return x;
    }, 
    format_Balance(temp) {
    // eslint-disable-next-line no-undef
    // return parseInt(BigInt(balance * 1000) / BigInt(10 ** decimals)) / 1000;
      if(temp >= 100) {
        return parseFloat(Number(temp.toString().match(/^\d+(?:\.\d{0,3})?/)));
      } else if(temp >=10 && temp < 100) {
        return parseFloat(Number(temp.toString().match(/^\d+(?:\.\d{0,4})?/)));
      }else if(temp >=1 && temp < 10) {
        return parseFloat(Number(temp.toString().match(/^\d+(?:\.\d{0,5})?/)));
      }else if(temp >=0.1 && temp < 1) {
        return parseFloat(Number(temp.toString().match(/^\d+(?:\.\d{0,6})?/)));
      }else if(temp >=0.01 && temp < 0.1) {
        return parseFloat(Number(temp.toString().match(/^\d+(?:\.\d{0,7})?/)));
      }else if(temp >=0.001 && temp < 0.01) {
        return parseFloat(Number(temp.toString().match(/^\d+(?:\.\d{0,8})?/)));
      } else {
        return parseFloat(Number(temp.toString().match(/^\d+(?:\.\d{0,9})?/)));
      }
    },
    formatBalance(balance, decimals) {
      if (!balance) {
        return "_";
      }
      return Utils.formatBalance(balance, decimals);
    },
    formatAddress(address) {
      return Utils.formatAddress(address);
    },
    calculate() {
      const amount = this.cspn_amount;
      var apy = 0;

      if (amount < 1337) {
        apy = 0.1;
      } else if (amount >= 1337 && amount < 10000) {
        apy = 0.1337;
      } else if (amount >= 10000 && amount < 25000) {
        apy = 0.15;
      } else if (amount >= 25000 && amount < 50000) {
        apy = 0.165;
      } else if (amount >= 50000 && amount < 100000) {
        apy = 0.18;
      } else if (amount >= 100000) {
        apy = 0.2;
      }
      this.cspn_apy = this.numberFormat(this.toFixed(apy * 100));
      this.cspn_year = this.numberFormat(this.toFixed(amount * apy));
      this.cspn_month = this.numberFormat(this.toFixed(amount * apy / 12));
      this.cspn_week = this.numberFormat(this.toFixed(amount * apy / 365 * 7));
      this.cspn_day = this.numberFormat(this.toFixed(amount * apy / 365));
    },
    async getHistory() {
      this.isLoading = true;
      const result = await Parse.getHistory(
        this.address,
        this.pageIndex * this.pageNum,
        this.pageNum,
        this.searchBy
      );
      this.results = result;
      this.isLoading = false;
    },
    async approve() {
      this.isLoadingApprove = true;
      try {
        await Web3.approve();
      } catch (e) {
        console.log("error", e);
      }
      this.isLoadingApprove = false;
    },
    async stake() {
      if (this.amountAdd <= 0) return;
      let isMax = false;
      if (this.amountAdd == this.formatBalance(this.balance)) {
        isMax = true;
      }
      this.isLoadingStake = true;
      try {
        await Web3.stake(this.amountAdd, isMax);
      } catch (e) {
        console.log("error", e);
      }
      this.isLoadingStake = false;
      this.amountAdd = 0;
      await this.getHistory();
    },
    async withdraw() {
      if (this.amountWithdraw <= 0) return;
      let isMax = false;
      if (this.amountWithdraw == this.formatBalance(this.staking)) {
        isMax = true;
      }
      this.isLoadingWithdraw = true;
      try {
        await Web3.withdraw(this.amountWithdraw, isMax);
      } catch (e) {
        console.log("error", e);
      }
      this.isLoadingWithdraw = false;
      this.amountWithdraw = 0;
      await this.getHistory();
    },
    async harvest() {
      if (this.amountHarvest <= 0) return;
      let isMax = false;
      if (this.amountWithdraw == this.formatBalance(this.reward)) {
        isMax = true;
      }
      this.isLoadingHarvest = true;
      try {
        await Web3.harvest(this.amountHarvest, isMax);
      } catch (e) {
        console.log("error", e);
      }
      this.isLoadingHarvest = false;
      this.amountHarvest = 0;
      await this.getHistory();
    },
    async reinvest() {
      if (this.amountHarvest <= 0) return;
      let isMax = false;
      if (this.amountWithdraw == this.formatBalance(this.reward)) {
        isMax = true;
      }
      this.isLoadingReinvest = true;
      try {
        await Web3.reinvest(this.amountHarvest, isMax);
      } catch (e) {
        console.log("error", e);
      }
      this.isLoadingReinvest = false;
      this.amountHarvest = 0;
      await this.getHistory();
    },
    async connect() {
      this.menuOpen = false;
      if (this.isConnecting) {
        return;
      }
      try {
        await Web3.init();
        this.isConnecting = true;
      } catch (e) {
        // this.alertModalTitle = "Error";
        // this.alertModalIcon = "error";
        // this.alertModalActive = true;
        // this.alertModalContent =
        //   "There was an error connecting your wallet. Please try again";
        // this.alertModalBtnOk = "Ok";
        // this.alertModalCallback = null;
        console.log("error: ", e);
      }
    },
    async getStatus() {
      const result = await Parse.getStatus();
      this.status = result;
    },
    async previousPage() {
      if (this.pageIndex == 0) return;
      this.pageIndex--;
      await this.getHistory();
    },
    async nextPage() {
      const result = await Parse.getHistory(
        this.address,
        (this.pageIndex + 1) * this.pageNum,
        this.pageNum,
        this.searchBy
      );
      if (result.length > 0) {
        this.pageIndex++;
        await this.getHistory();
        // this.results = result;
      }
    },
    formatDate(date) {
      return dateFormat(date, "yyyy/mm/dd HH:MM");
    },
  },
  computed: {
    ...mapGetters([
      "address",
      "balance",
      "staking",
      "reward",
      "total_reward",
      "isApproved",
    ]),
  },
  watch: {
    async address() {
      if (!this.address) {
        return;
      }
      // Todo: set default eligible false
    },
    async pageNum() {
      this.pageIndex = 0;
      this.getHistory();
    },
    async searchBy() {
      this.pageIndex = 0;
      this.getHistory();
    },
  },

  async mounted() {
    // await Web3.checkConnection();
    Parse.init(true);
    await Web3.saveStatus();
    await Web3.initTimer();
    await this.getStatus();
    Observer.$on(E_SIGNED, async () => {
      await this.getHistory();
    });
    Observer.$on(E_REJECT_SIGN, async () => {
      this.isConnecting = false;
      await this.getStatus();
      // console.log("disconnected");
    });
  },
  data() {
    return {
      isConnecting: false,
      // Alert Modal
      // alertModalTitle: "",
      // alertModalIcon: "success",
      // alertModalActive: false,
      // alertModalContent: "",
      // alertModalBtnOk: "",
      // alertModalCallback: null,

      // Staking
      active: 0,
      amountAdd: 0,
      amountWithdraw: 0,
      amountHarvest: 0,
      pageNum: 10,
      searchBy: "All",
      pageIndex: 0,
      results: [],
      isLoadingApprove: false,
      isLoadingStake: false,
      isLoadingWithdraw: false,
      isLoadingHarvest: false,
      isLoadingReinvest: false,
      isLoading: false,

      // Calculator
      cspn_amount: 0,
      cspn_apy: 0,
      cspn_year: 0,
      cspn_month: 0,
      cspn_week: 0,
      cspn_day: 0,

      // Performance
      performance_amount: 0,
      performance_apy: 0,
      performance_day: 0,
      performance_week: 0,
      performance_month: 0,
      performance_year: 0,

      // Mobile Menu
      menuOpen: false,

      // Status
      status: null,
    };
  },
};
</script>