import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
const { formatBytes32String, parseBytes32String } = require("ethers/lib/utils");

import Web3Modal from "web3modal";

import Observer from "./Observer";
import store from "../store/index";
import {
  E_ACCOUNT_CHANGED,
  E_SIGNED,
  E_REJECT_SIGN,
} from "../constants/events";
import Config from "../config";
import Utils from "./Utils";
import Parse from "./Parse";

import ABI from "../constants/abi";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      // infuraId: Config.INFURA_ID, // required
      rpc: {
        [Config.CHAIN_ID]: Config.MAINNET_RPC,
      },
    },
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions, // required
});

class Web3Helper {
  constructor() {
    this.web3 = null;
    this.account = {};
    this.address = null;
    this.balance = 0;
    this.staking = 0;
    this.reward = 0;
    this.timer = null;
    this.provider = null;
    this.subscription = null;
    this.gasPrice = 0;
  }

  createAccount() {
    return this.web3.eth.accounts.create();
  }

  isAdmin() {
    return this.address.toLowerCase() == Config.ADMIN.toLowerCase();
  }

  async disconnect() {
    try {
      await web3Modal.clearCachedProvider();
    } catch (e) {
      console.log("error", e);
    }
    store.commit("SET", ["address", null]);
    // await this.saveStatus();
    this.address = null;
    clearInterval(this.timer);
    // localStorage.removeItem("isConnected");
    Observer.$emit(E_REJECT_SIGN);
  }

  async saveStatus() {
    const web3 = new Web3(Config.MAINNET_RPC);

    const staking_contract = new web3.eth.Contract(
      ABI.StakingABI,
      Web3.utils.toChecksumAddress(Config.STAKING_ADDRESS)
    );

    const total_stake = await staking_contract.methods.total_stake().call();
    const total_reward = await staking_contract.methods.total_reward().call();

    // const token_contract = new web3.eth.Contract(
    //   ABI.TokenABI,
    //   Web3.utils.toChecksumAddress(Config.CSPN_ADDRESS)
    // );

    // const total_balance = await token_contract.methods
    //   .balanceOf(Config.STAKING_ADDRESS)
    //   .call();

    // console.log(total_balance);

    await Parse.saveStatus(
      Utils.formatBigInt(total_stake),
      Utils.formatBigInt(total_reward),
      Utils.formatBigInt(6453699000000000000000000 - total_reward)
    );
  }

  async init() {
    const provider = await web3Modal.connect();
    this.provider = provider;
    this.web3 = new Web3(provider);
    // localStorage.setItem("isConnected", true);
    // await this.addToken();

    this.initObserver();

    const accounts = await this.web3.eth.getAccounts();

    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
      // console.log('Account changed', accounts);
      Observer.$emit(E_ACCOUNT_CHANGED, accounts);
    });

    // Subscribe to chainId change
    provider.on("chainChanged", async (chainId) => {
      // console.log('Chain Changed', chainId);
      // eslint-disable-next-line no-undef
      if (BigInt(chainId) != BigInt(Config.CHAIN_ID)) {
        this.switchNetwork();
      }
    });

    // Subscribe to provider connection
    provider.on("connect", (info) => {
      console.log("Chain Connected", info.chainId);
    });

    // Subscribe to provider disconnection
    provider.on("disconnect", (error) => {
      console.log("Wallet Disconnected", error.code, error.message);
    });

    Observer.$emit(E_ACCOUNT_CHANGED, accounts);
  }

  async addToken () {
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await this.provider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: '0x9A0b381394fbE689B344d1ebd2d4DccFF31adf87', // The address that the token is at.
            symbol: 'CSPN', // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            // image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log('Thanks for your interest!');
      } else {
        console.log('Your loss!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async switchNetwork() {
    // eslint-disable-next-line no-undef
    const chainId = "0x" + BigInt(Config.CHAIN_ID).toString(16);
    if (this.isNetworkRequest) {
      return;
    }
    this.isNetworkRequest = true;
    try {
      await this.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId }],
      });
    } catch (switchError) {
      // console.log('Switch Network Error', switchError);
      if (switchError.code === 4902) {
        try {
          await this.provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chainId,
                chainName: "Polygon Mainnet",
                nativeCurrency: {
                  name: "MATIC ",
                  symbol: "MATIC", // 2-6 characters long
                  decimals: 18,
                },
                rpcUrls: [Config.MAINNET_RPC],
                blockExplorerUrls: [Config.EXPLORER_LINK],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
          // console.log('Add Network Error', addError);
        }
      }
    }
    this.isNetworkRequest = false;
  }

  initObserver() {
    Observer.$off(E_ACCOUNT_CHANGED);

    Observer.$on(E_ACCOUNT_CHANGED, async (accounts) => {
      this.address = accounts[0];

      const chainId = await this.web3.eth.getChainId();
      if (chainId != Config.CHAIN_ID) {
        await this.switchNetwork();
      }

      const balance = await this.getCSPNBalance();
      this.balance = balance;
      const approve = await this.getTokenContract()
        .methods.allowance(this.address, Config.STAKING_ADDRESS)
        .call();
      if (approve > 0) {
        store.commit("SET", ["isApproved", true]);
      } else {
        store.commit("SET", ["isApproved", false]);
      }
      const staking = await this.getStakingContract()
        .methods.getStakeAmount(this.address)
        .call();
      this.staking = staking;
      const reward = await this.getStakingContract()
        .methods.getReward(this.address)
        .call();
      this.reward = reward;
      const reinvest_reward = await Parse.getReinvestAmount(this.address);
      const total_reward = await this.getStakingContract()
        .methods.getTotalReward(this.address)
        .call();
      this.total_reward = total_reward + Web3.utils.toWei(reinvest_reward.toString());

      store.commit("SET", ["address", this.address]);
      store.commit("SET", ["balance", this.balance]);
      store.commit("SET", ["staking", this.staking]);
      store.commit("SET", ["reward", this.reward]);
      store.commit("SET", ["total_reward", this.total_reward]);

      // await this.saveStatus();

      // await this.initAccount();
      Observer.$emit(E_SIGNED);
    });
  }

  initTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    const handler = () => {
      this.updateBalance();
      fetch("https://gasstation-mainnet.matic.network/v2")
        .then((response) => response.json())
        .then((json) => (this.gasPrice = json.fast.maxFee));
    };
    handler();
    this.timer = setInterval(handler, 300000);
  }

  async initAccount() {
    // localStorage.clear();
    // Parse.init(true);
    // console.log("init");
    // this.initTimer();
    // Observer.$emit(E_SIGNED);
  }

  bytes32(str) {
    return formatBytes32String(str);
  }

  parseBytes32(bytes) {
    return parseBytes32String(bytes);
  }

  isAddress(address) {
    return this.web3.utils.isAddress(address);
  }

  async getBalance(address) {
    return this.web3.eth.getBalance(address);
  }

  async getCSPNBalance() {
    const web3 = new Web3(new Web3.providers.HttpProvider(Config.MAINNET_RPC));
    const sniper = new web3.eth.Contract(ABI.TokenABI, Config.CSPN_ADDRESS);
    const balance = await sniper.methods.balanceOf(this.address).call();
    return balance;
  }

  async updateBalance() {
    if (this.address == null) return;
    const chainId = await this.web3.eth.net.getId();
    // eslint-disable-next-line no-undef
    if (BigInt(chainId) != BigInt(Config.CHAIN_ID)) {
      await this.switchNetwork();
      return;
    }

    const balance = await this.getCSPNBalance();
    this.balance = balance;
    const staking = await this.getStakingContract()
      .methods.getStakeAmount(this.address)
      .call();
    this.staking = staking;
    const reward = await this.getStakingContract()
      .methods.getReward(this.address)
      .call();
    this.reward = reward;
    const reinvest_reward = await Parse.getReinvestAmount(this.address);
    const total_reward = await this.getStakingContract()
      .methods.getTotalReward(this.address)
      .call();
    this.total_reward = total_reward + Web3.utils.toWei(reinvest_reward.toString());

    store.commit("SET", ["address", this.address]);
    store.commit("SET", ["balance", this.balance]);
    store.commit("SET", ["staking", this.staking]);
    store.commit("SET", ["reward", this.reward]);
    store.commit("SET", ["total_reward", this.total_reward]);

    // await this.saveStatus();
  }

  sign(address, nonce) {
    const message = this.web3.eth.accounts.hashMessage(
      `I am signing my one-time nonce: ${nonce}`
    );
    return this.web3.eth.sign(message, address);
  }

  async approve() {
    await this.getTokenContract()
      .methods.approve(
        Config.STAKING_ADDRESS,
        Utils.formatBigInt(13370000 * 10 ** 18)
      )
      .send({
        from: this.address,
        gasPrice: parseInt(this.gasPrice * 1.1 * 1000000000).toString(),
      });
    store.commit("SET", ["isApproved", true]);
    var _this = this;
    var _store = store;
    var count = 0;
    var delay = setInterval(function () {
      _this;
      _store;
      count;
      _store.commit("SET", ["isApproved", true]);
      count = count + 1;
      if (count > 14) {
        clearInterval(delay);
        count = 0;
      }
    }, 6000);
  }

  async stake(amount, isMax) {
    if (!isMax) {
      const stake = await this.getStakingContract()
        .methods.stake(Utils.formatBigInt(amount * 10 ** 18))
        .send({
          from: this.address,
          gasPrice: parseInt(this.gasPrice * 1.1 * 1000000000).toString(),
        });
      await this.updateBalance();
      await Parse.saveHistory(
        this.address,
        "Stake",
        amount,
        stake.transactionHash
      );
    } else {
      const temp = Utils.formatBalance(this.balance).toString();
      const stake = await this.getStakingContract()
        .methods.stake(this.balance)
        .send({
          from: this.address,
          gasPrice: parseInt(this.gasPrice * 1.1 * 1000000000).toString(),
        });
      await this.updateBalance();
      await Parse.saveHistory(
        this.address,
        "Stake",
        temp,
        stake.transactionHash
      );
    }
    var _this = this;
    var count = 0;
    var delay = setInterval(function () {
      _this;
      count;
      _this.updateBalance();
      count = count + 1;
      if (count > 14) {
        clearInterval(delay);
        count = 0;
      }
    }, 6000);
  }

  async withdraw(amount, isMax) {
    const contractHandler = this.getStakingContract();
    if (!isMax) {
      const withdraw = await contractHandler.methods
        .withdraw(Utils.formatBigInt(amount * 10 ** 18))
        .send({
          from: this.address,
          gasPrice: parseInt(this.gasPrice * 1.1 * 1000000000).toString(),
        });
      await this.updateBalance();
      await Parse.saveHistory(
        this.address,
        "Withdraw",
        amount,
        withdraw.transactionHash
      );
    } else {
      const temp = Utils.formatBalance(this.staking).toString();
      const withdraw = await contractHandler.methods
        .withdraw(this.staking)
        .send({
          from: this.address,
          gasPrice: parseInt(this.gasPrice * 1.1 * 1000000000).toString(),
        });
      await this.updateBalance();
      await Parse.saveHistory(
        this.address,
        "Withdraw",
        temp,
        withdraw.transactionHash
      );
    }
    var _this = this;
    var count = 0;
    var delay = setInterval(function () {
      _this;
      count;
      _this.updateBalance();
      count = count + 1;
      if (count > 14) {
        clearInterval(delay);
        count = 0;
      }
    }, 6000);
  }

  async harvest(amount, isMax) {
    const contractHandler = this.getStakingContract();
    if (!isMax) {
      const harvest = await contractHandler.methods
        .harvest(Utils.formatBigInt(amount * 10 ** 18))
        .send({
          from: this.address,
          gasPrice: parseInt(this.gasPrice * 1.1 * 1000000000).toString(),
        });
      await this.updateBalance();
      await Parse.saveHistory(
        this.address,
        "Harvest",
        amount,
        harvest.transactionHash
      );
    } else {
      const temp = Utils.formatBalance(this.reward).toString();
      const harvest = await contractHandler.methods
        .harvest(this.reward)
        .send({
          from: this.address,
          gasPrice: parseInt(this.gasPrice * 1.1 * 1000000000).toString(),
        });
      await this.updateBalance();
      await Parse.saveHistory(
        this.address,
        "Harvest",
        temp,
        harvest.transactionHash
      );
    }
    var _this = this;
    var count = 0;
    var delay = setInterval(function () {
      _this;
      count;
      _this.updateBalance();
      count = count + 1;
      if (count > 14) {
        clearInterval(delay);
        count = 0;
      }
    }, 6000);
  }

  async reinvest(amount, isMax) {
    const contractHandler = this.getStakingContract();
    if (!isMax) {
      const reinvest = await contractHandler.methods
        .reinvest(Utils.formatBigInt(amount * 10 ** 18))
        .send({
          from: this.address,
          gasPrice: parseInt(this.gasPrice * 1.1 * 1000000000).toString(),
        });
      await this.updateBalance();
      await Parse.saveHistory(
        this.address,
        "Reinvest",
        amount,
        reinvest.transactionHash
      );
    } else {
      const temp = Utils.formatBalance(this.reward).toString();
      const reinvest = await contractHandler.methods
        .reinvest(this.reward)
        .send({
          from: this.address,
          gasPrice: parseInt(this.gasPrice * 1.1 * 1000000000).toString(),
        });
      await this.updateBalance();
      await Parse.saveHistory(
        this.address,
        "Reinvest",
        temp,
        reinvest.transactionHash
      );
    }
    var _this = this;
    var count = 0;
    var delay = setInterval(function () {
      _this;
      count;
      _this.updateBalance();
      count = count + 1;
      if (count > 14) {
        clearInterval(delay);
        count = 0;
      }
    }, 6000);
  }

  getTokenContract(address) {
    if (!address) {
      address = Config.CSPN_ADDRESS;
    }
    return new this.web3.eth.Contract(ABI.TokenABI, address);
  }

  getStakingContract(address) {
    if (!address) {
      address = Config.STAKING_ADDRESS;
    }
    return new this.web3.eth.Contract(
      ABI.StakingABI,
      Web3.utils.toChecksumAddress(address)
    );
  }

  async decimals(address) {
    if (!address) {
      address = Config.CSPN_ADDRESS;
    }
    const tokenContract = this.getTokenContract();
    return parseInt(await tokenContract.methods.decimals().call());
  }

  async getTokenDetails(address) {
    const contract = this.getTokenContract(address);
    const result = {};
    result.owner = await contract.methods.owner().call();
    result.totalSupply = await contract.methods.totalSupply().call();
    result.decimals = await contract.methods.decimals().call();
    result.name = await contract.methods.name().call();
    result.symbol = await contract.methods.symbol().call();
    return result;
  }

  async getGasPrice() {
    return await this.web3.eth.getGasPrice();
  }

  async estimateGasLimit(option) {
    // from, to, data, value
    return await this.web3.eth.estimateGas(option);
  }

  async send(transaction, privateKey, options) {
    // from, to, gas: gasLimit, value, gasPrice / (maxFeePerGas, maxPriorityFeePerGas)
    if (transaction) {
      options.data = transaction.encodeABI();
    }
    const signedTx = await this.web3.eth.accounts.signTransaction(
      options,
      privateKey
    );

    const result = await this.web3.eth.sendSignedTransaction(
      signedTx.rawTransaction
    );
    result.hash = result.blockHash;
    return result;
  }
}

const helper = new Web3Helper();
// helper.init();
export default helper;