<template>
  <div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
  },
  computed: {
    ...mapGetters(["account", "balance"]),
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
</style>