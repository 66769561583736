<template>
  <div id="footer">
    <div class="footer-left">
      <p>&copy; 2022 Crypto Sports Network</p>
    </div>
    <div class="footer-right">
      <ul class="iconMenu">
        <li>
          <a
            href="https://www.cspn.io"
            target="_blank"
            title="CSPN Official Website"
          >
            <i class="fas fa-globe"></i
          ></a>
        </li>
        <li>
          <a
            href="https://discord.gg/vVqU7b7"
            target="_blank"
            title="CSPN on Discord"
          >
            <i class="fab fa-discord"></i
          ></a>
        </li>
        <li>
          <a
            href="https://t.me/CSPNio"
            target="_blank"
            title="CSPN on Telegram"
          >
            <i class="fab fa-telegram"></i>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/CSPNio"
            target="_blank"
            title="CSPN on Twitter"
          >
            <i class="fab fa-twitter"></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/CSPNio"
            target="_blank"
            title="CSPN on Facebook"
          >
            <i class="fab fa-facebook-f"></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/cspn_io"
            target="_blank"
            title="CSPN on Instagram"
          >
            <i class="fab fa-instagram"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  components: {},
  computed: {
    ...mapGetters(["address"]),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
</style>